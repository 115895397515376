<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <div>测试在线打开文件</div>

    <Button @click="test">downFile and openDocument</Button>
    <Button @click="openDocument">openDocument</Button>
  </div>
</template>

<script>


export default {
  name: 'OpenDoc',
  data () {
    return {

    }
  },
  async mounted() {
  },
  methods: {
    test () {
      tt.downloadFile({
        "url": "https://sf3-ttcdn-tos.pstatp.com/obj/larkcloud-file-storage/baas/aff2e2/9e0433a27af52897_1600340084767.xlsx",
        success(res) {
          const filePath = res.tempFilePath
          tt.openDocument({
            filePath,
            showMenu: true,
            success(res) {
              console.log(JSON.stringify(res));
            },
            fail(res) {
              console.log(`openDocument fail: ${JSON.stringify(res)}`);
            },
          });
          // alert(JSON.stringify(res))
          // console.log(JSON.stringify(res));
        },
        fail(res) {
          console.log(`downloadFile fail: ${JSON.stringify(res)}`);
        }
      })
    },
    openDocument () {
      tt.filePicker({
        success(res) {
          const filePath = res.list[0].path;
          tt.openDocument({
            filePath,
            showMenu: true,
            success(res) {
              console.log(JSON.stringify(res));
            },
            fail(res) {
              console.log(`openDocument fail: ${JSON.stringify(res)}`);
            },
          });
        },
      });
    }
  },
};
</script>
